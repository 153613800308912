
// Height Helpers
// ========================================================================
.height-50 {
  height: 50px;
}
.height-100 {
  height: 100px;
}
.height-120 {
  height: 120px;
}
.height-150 {
  height: 150px;
}
.height-200 {
  height: 200px;
}
.height-250 {
  height: 250px;
}
.height-300 {
  height: 300px;
}
.height-350 {
  height: 350px;
}
.height-400 {
  height: 400px;
}
.height-450 {
  height: 450px;
}
.height-500 {
  height: 500px;
}
.height-full {
  height: 100% !important;
}


@media (max-width: @screen-xs-max) {
  .height-xs-50 {
    height: 50px;
  }
  .height-xs-100 {
    height: 100px;
  }
  .height-xs-120 {
    height: 120px;
  }
  .height-xs-150 {
    height: 150px;
  }
  .height-xs-200 {
    height: 200px;
  }
  .height-xs-250 {
    height: 250px;
  }
  .height-xs-300 {
    height: 300px;
  }
  .height-xs-350 {
    height: 350px;
  }
  .height-xs-400 {
    height: 400px;
  }
  .height-xs-450 {
    height: 450px;
  }
  .height-xs-500 {
    height: 500px;
  }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .height-sm-50 {
    height: 50px;
  }
  .height-sm-100 {
    height: 100px;
  }
  .height-sm-120 {
    height: 120px;
  }
  .height-sm-150 {
    height: 150px;
  }
  .height-sm-200 {
    height: 200px;
  }
  .height-sm-250 {
    height: 250px;
  }
  .height-sm-300 {
    height: 300px;
  }
  .height-sm-350 {
    height: 350px;
  }
  .height-sm-400 {
    height: 400px;
  }
  .height-sm-450 {
    height: 450px;
  }
  .height-sm-500 {
    height: 500px;
  }
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  .height-md-50 {
    height: 50px;
  }
  .height-md-100 {
    height: 100px;
  }
  .height-md-120 {
    height: 120px;
  }
  .height-md-150 {
    height: 150px;
  }
  .height-md-200 {
    height: 200px;
  }
  .height-md-250 {
    height: 250px;
  }
  .height-md-300 {
    height: 300px;
  }
  .height-md-350 {
    height: 350px;
  }
  .height-md-400 {
    height: 400px;
  }
  .height-md-450 {
    height: 450px;
  }
  .height-md-500 {
    height: 500px;
  }
}

@media (min-width: @screen-lg-min) {
  .height-lg-50 {
    height: 50px;
  }
  .height-lg-100 {
    height: 100px;
  }
  .height-lg-120 {
    height: 120px;
  }
  .height-lg-150 {
    height: 150px;
  }
  .height-lg-200 {
    height: 200px;
  }
  .height-lg-250 {
    height: 250px;
  }
  .height-lg-300 {
    height: 300px;
  }
  .height-lg-350 {
    height: 350px;
  }
  .height-lg-400 {
    height: 400px;
  }
  .height-lg-450 {
    height: 450px;
  }
  .height-lg-500 {
    height: 500px;
  }
}
