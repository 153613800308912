.site-print {
  padding-top: 0;

  .site-navbar,
  .site-menubar,
  .site-gridmenu,
  .site-footer {
    display: none;
  }

  .page {
    margin: 0 !important;
  }
}
