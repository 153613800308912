.selectize-input{
  border:1px solid #e4eaec;
  box-shadow: none;
  -webkit-box-shadow: none;


}

.selectize-control{
  .item {
    //background: @color-blue-100 !important;
    background: none !important;
    padding: 2px 5px;
    border-radius: 3px;
  }
  &.category1{
    .item {
      background: @color-cyan-600 !important;
      color: #fff !important;
    }
  }

  &.category2{
    .item{
    background: @color-purple-600 !important;
    color: #fff !important;
    }
  }
}

.autosuggest-box{
  .name{
    font-weight: 400;
  }
  .autosuggest-box-info{
    display:block;
  }
  .phone{
    display:inline-block;
    margin-left:15px;
  }

  .email{
    display:inline-block;
  }
}

//.input-group .selectize-control{
//  margin-top:5px;
//}

.input-group-withbtn .input-group-btn{
  vertical-align:top;
}