
.site-navbar {
  background-color: @site-navbar-header-bg;

  .navbar-header {
    background-color: transparent;
    color: @site-navbar-header-color;

    .navbar-toggle {
      color: @site-navbar-header-color;
    }

    .hamburger {
      &:before,
      &:after,
      .hamburger-bar {
        background-color: @site-navbar-header-color;
      }
    }

    .navbar-brand {
      color: @site-navbar-brand-color;
      font-family: @brand-font-family;
      cursor: pointer;
    }

    @media (min-width: @grid-float-breakpoint) {
      width: @site-menubar-unfold-width;
    }
  }

  .navbar-container {
    @media (min-width: @grid-float-breakpoint) {
      margin-left: @site-menubar-unfold-width;
    }

    background-color: @inverse;
  }

  &.navbar-inverse {
    .navbar-container {
      background-color: transparent;
    }
  }
}
