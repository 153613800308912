/*IMAGE EDITOR */

.cropper-preview {
  overflow: hidden;
}

.img-preview {
  float: left;
  margin: 0 10px 10px 0;
  overflow: hidden;
  > img {
    max-width: 100%;
  }
}

.preview-lg {
  width: 263px;
  height: 148px;
}

.preview-md {
  width: 139px;
  height: 78px;
}

.preview-sm {
  width: 69px;
  height: 39px;
}

.preview-xs {
  width: 35px;
  height: 20px;
  margin-right: 0;
}

.cropper,
.cropper-toolbar {
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .cropper {
    max-height: 600px;
  }
}

@media (min-width: 769px) {
  .cropper {
    max-height: 400px;
  }
}

@media (max-width: 768px) {
  .cropper {
    max-height: 300px;
  }
}

@media (max-width: 480px) {
  .cropper {
    max-height: 246px;
  }
}

@media (max-width: 586px) {
  .btn-group {
    .btn {
      padding: 6px 8px;
    }
  }

  .cropper {
    max-height: 246px;
  }
}
