.popup{
  position:fixed;
  width: 0;
  height: 0;
  border:0px solid transparent;
  border-top-color:#fff;
  border-width:0 35px;

  z-index:10001;
}

.popup.loading{
  //background:url('preloader.gif') no-repeat center center #eee;
  box-shadow:0 0 50px #ccc inset, 0 0 5px #666;
  top:0 !important;
  left:0 !important;
  right:0;
  bottom:0;
  margin:auto;
  border-width:0 !important;
}

.frameCacheDiv iframe{
  border:none;
  top:0;
  left:0;
  bottom:0;
  right:0;
  margin:auto;
  position:fixed;
  z-index: 10010;
}

.popupBG{
  background-color:rgba(0,0,0,0.5);
  position:fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
  opacity:0;
  z-index:10000;
}

.fullscreener{
  position: fixed;
  width:100%;
  height: 100%;
  z-index:10001;
  top:0;
  left:0;
  right:0;
  bottom:0;
//  background: rgba(255,255,255,.95);
  background: #fff;
}

#iframediv{
    width: 100%;
    height: 100%;
}
.fullscreener  iframe{
    border:none;
    top:10%;
    left:0;
    bottom:0;
    right:0;
    width:100%;
    height: 90%;
    max-width: 800px;
    margin:auto;
    position:fixed;
   // z-index: 10010;

}

.fullscreener-nav{
  //margin:0 auto;
  text-align:center;

  .active{
    color: @color-blue-600;
  }
}