.transform(@transform) {
  -ms-transform: @transform;
  -webkit-transform: @transform;
  transform: @transform;
}

.transform-style(@transform-style) {
  -ms-transform-style: @transform-style;
  -webkit-transform-style: @transform-style;
  transform-style: @transform-style;
}