.grid-stack-item-content {
  font-size: 20px;
  color: #526069;
  text-align: center;
  background: white;
  .fa {
    display: block;
    margin: 20px 0 10px;
    font-size: 64px;
  }
}