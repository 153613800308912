

/* fullcalendar Custom style
--------------------------------------------------------------------------------------------------*/
.fc {
  td {
    &.fc-widget-header {
      padding-top: 20px;
      padding-bottom: 20px;
      border-width: 0px;
      font-size: 14px;
      text-transform: uppercase;
    }
    &.fc-widget-content {
      border-width: 1px 0 0;
    }
    &.fc-day.fc-widget-content {
      + .fc-widget-content {
        border-left-width: 1px;
      }
    }
  }
  .fc-row {
    border-width: 0;
  }
  table {
    > thead {
      > tr {
        > th {
          border-width: 0;
          font-weight: @font-weight-light;
        }
      }
    }
  }
  .fc-toolbar {
    h2 {
      display: inline-block;
      font-size: 20px;
      vertical-align: sub;
    }
  }
  .fc-axis {
    &.fc-time {
      text-transform: uppercase;
    }
  }
  .fc-toolbar {
    position: relative;
  }
}


.fc-button {
  &.fc-prev-button,
  &.fc-next-button {
    outline: none;
    box-shadow: none;
    background-image: none;
    background-color: transparent;
    border: 0px solid transparent;
  }
  &.fc-month-button,
  &.fc-agendaWeek-button,
  &.fc-agendaDay-button {
    height: auto;
    padding: 8px 14px;
    font-size: 14px;
    box-shadow: none;
    background-image: none;
    text-transform: capitalize;
    background-color:#f6f8f8;
    border-color:@color-blue-grey-200;
    outline: none;
  }
  &.fc-state-active,
  &.fc-state-hover {
    background-color: @inverse;
  }
}

.fc-unthemed {
  .fc-today {
    background-color: #f4f7f8;
  }
}


.fc-toolbar {
  .fc-right {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.fc-agendaWeek-view .fc-event, .fc-agendaDay-view .fc-event {
  border-radius: 3px;
}

.fc-row {
  &.fc-widget-header {
    border-color: transparent;
  }
}

//calendar page style
.calendar-container {
  overflow: hidden;
}

.panel-heading {
  border-width: 0;
  padding-top: 10px;
}

.panel-body {
  padding-top: 10px;
}

.calendar-list {
  // margin-bottom: 0;

  .list-group-item {
    // border-width: 0;
    // padding-left: 0;
    // padding-right: 0;
    cursor:pointer;
  }
}
.fc-event {
  border-radius: 10px;
  background-color: @color-blue-600;
  border-color: @color-blue-600;
}
.fc-day-grid-event {
  .fc-content {
    line-height: 1em;
    padding: 2px 10px;
  }
}
.fc-highlight {
  background: @gray-lightest;
}

//touchspin
.bootstrap-touchspin {
  .input-group-btn-vertical {
    i {
      left: 3px;
    }
  }
}

@media (max-width: @screen-sm-max) {
  .fc-toolbar {
    margin-bottom: 60px;
    .fc-center {
      white-space: nowrap;
    }
    .fc-right {
      top: 50px;
    }
  }
  .fc-button.fc-prev-button {
    padding-left: 0;
  }
  .fc-button.fc-next-button {
    padding-right: 0;
  }
}
