.page-aside.aside-fixed{
  position:fixed;
  top: @site-navbar-height;
  left: @page-aside-width;
  bottom: 0;
  max-height: 100%;
  max-width: 100%;
  //overflow: hidden;
  .page-aside-inner{
    overflow:hidden;
    height:100%;

  }
}


@media (max-width: @screen-md-max) {
  .page-aside.aside-fixed {
      left:  @site-menubar-fold-width;
  }
}
@media (max-width: @grid-float-breakpoint-max){
  .page-aside.aside-fixed {
    left:  0;
  }
}
