.table-render-small{

//  .avatar{
//    width: 55px;
//  }
  .render-details{
    float: left;
  }
  .render-name{
    font-weight: 400;
  }
  .render-email, .render-phone{
    display:block;
    font-size: 90%;
  }
}

.activity-status{
  padding:7px;
  display: inline-block;
  i{
    font-size: 24px;
    color: @color-blue-600;
  }

  &.activity-status-0.activity-timeframe-passed{

    color: @color-red-600;

    i{
      color: @color-red-600;
    }
  }

  &.activity-status-0{
    i{
    font-size: 20px;
    }
  }

  &.activity-status-1{
    color: @color-green-600;
    i{
      color: @color-green-600;
    }
  }
}


.table-slidepanel-small{
  font-size: 12px !important;

  label.btn{
    padding: 3px 5px;
  }
}

.labelish{
  padding: 2px;
  cursor: pointer;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;
  white-space: nowrap;
  background: transparent;
  display: inline-block;
  i{
    padding-right:2px;
  }

  &:hover{
    border-bottom: 1px dotted @color-blue-grey-300;
  }

}