.upload-document{
  padding: 5px;


  &.inline-document{
    .upload-document-wrapper {
      min-height: 40px;
    }
  }

  .upload-document-wrapper{
    border: 1px solid #eaeef1;
    min-height: 72px;

  }



  img{
    max-height:70px;
  }

  .caption{
    margin-top: 10px;
  }
}

.text-ellipsis{
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

