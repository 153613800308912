.mapping-point{
  z-index:10000;
}

#points-list{
  .in-use{
    display:none;
  }

  &.show-in-use{
    .in-use{
      display: block;
      opacity: 0.5;
    }
  }
}

.in-the-wrapper.mapping-point{
    background: rgba(0,0,0,.5);
    border-radius: 100%;

    border: 1px solid #fff;
    display:block;
    padding: 0;
    text-align: center;

  div{
    padding:0;
    height: 20px;
    width: 20px;
  }

  span{
    display: none;
  }


}