// Margin Helpers
// ========================================================================
.margin-0 {
  margin: 0 !important;
}
.margin-3 {
  margin: 3px !important;
}
.margin-5 {
  margin: 5px !important;
}
.margin-10 {
  margin: 10px !important;
}
.margin-15 {
  margin: 15px !important;
}
.margin-20 {
  margin: 20px !important;
}
.margin-25 {
  margin: 25px !important;
}
.margin-30 {
  margin: 30px !important;
}
.margin-35 {
  margin: 35px !important;
}
.margin-40 {
  margin: 40px !important;
}
.margin-45 {
  margin: 45px !important;
}
.margin-50 {
  margin: 50px !important;
}
.margin-60 {
  margin: 60px !important;
}
.margin-70 {
  margin: 70px !important;
}
.margin-80 {
  margin: 80px !important;
}

// margin vertical
// ---------------
.margin-vertical-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.margin-vertical-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}
.margin-vertical-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.margin-vertical-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.margin-vertical-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.margin-vertical-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.margin-vertical-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.margin-vertical-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.margin-vertical-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}
.margin-vertical-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.margin-vertical-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}
.margin-vertical-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.margin-vertical-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.margin-vertical-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}
.margin-vertical-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

// margin horizontal
// -----------------
.margin-horizontal-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.margin-horizontal-3 {
  margin-right: 3px !important;
  margin-left: 3px !important;
}
.margin-horizontal-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}
.margin-horizontal-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.margin-horizontal-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}
.margin-horizontal-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}
.margin-horizontal-25 {
  margin-right: 25px !important;
  margin-left: 25px !important;
}
.margin-horizontal-30 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}
.margin-horizontal-35 {
  margin-right: 35px !important;
  margin-left: 35px !important;
}
.margin-horizontal-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}
.margin-horizontal-45 {
  margin-right: 45px !important;
  margin-left: 45px !important;
}
.margin-horizontal-50 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}
.margin-horizontal-60 {
  margin-right: 60px !important;
  margin-left: 60px !important;
}
.margin-horizontal-70 {
  margin-right: 70px !important;
  margin-left: 70px !important;
}
.margin-horizontal-80 {
  margin-right: 80px !important;
  margin-left: 80px !important;
}
// margin top
// ----------
.margin-top-0 {
  margin-top: 0 !important;
}
.margin-top-3 {
  margin-top: 3px !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-top-15 {
  margin-top: 15px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-top-25 {
  margin-top: 25px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.margin-top-35 {
  margin-top: 35px !important;
}
.margin-top-40 {
  margin-top: 40px !important;
}
.margin-top-45 {
  margin-top: 45px !important;
}
.margin-top-50 {
  margin-top: 50px !important;
}
.margin-top-60 {
  margin-top: 60px !important;
}
.margin-top-70 {
  margin-top: 70px !important;
}
.margin-top-80 {
  margin-top: 80px !important;
}

// margin bottom
// -------------
.margin-bottom-0 {
  margin-bottom: 0 !important;
}
.margin-bottom-3 {
  margin-bottom: 3px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-bottom-25 {
  margin-bottom: 25px !important;
}
.margin-bottom-30 {
  margin-bottom: 30px !important;
}
.margin-bottom-35 {
  margin-bottom: 35px !important;
}
.margin-bottom-40 {
  margin-bottom: 40px !important;
}
.margin-bottom-45 {
  margin-bottom: 45px !important;
}
.margin-bottom-50 {
  margin-bottom: 50px !important;
}
.margin-bottom-60 {
  margin-bottom: 60px !important;
}
.margin-bottom-70 {
  margin-bottom: 70px !important;
}
.margin-bottom-80 {
  margin-bottom: 80px !important;
}

// margin left
// -----------
.margin-left-0 {
  margin-left: 0 !important;
}
.margin-left-3 {
  margin-left: 3px !important;
}
.margin-left-5 {
  margin-left: 5px !important;
}
.margin-left-10 {
  margin-left: 10px !important;
}
.margin-left-15 {
  margin-left: 15px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.margin-left-25 {
  margin-left: 25px !important;
}
.margin-left-30 {
  margin-left: 30px !important;
}
.margin-left-35 {
  margin-left: 35px !important;
}
.margin-left-40 {
  margin-left: 40px !important;
}
.margin-left-45 {
  margin-left: 45px !important;
}
.margin-left-50 {
  margin-left: 50px !important;
}
.margin-left-60 {
  margin-left: 60px !important;
}
.margin-left-70 {
  margin-left: 70px !important;
}
.margin-left-80 {
  margin-left: 80px !important;
}

// margin right
// ------------
.margin-right-0 {
  margin-right: 0 !important;
}
.margin-right-3 {
  margin-right: 3px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-right-10 {
  margin-right: 10px !important;
}
.margin-right-15 {
  margin-right: 15px !important;
}
.margin-right-20 {
  margin-right: 20px !important;
}
.margin-right-25 {
  margin-right: 25px !important;
}
.margin-right-30 {
  margin-right: 30px !important;
}
.margin-right-35 {
  margin-right: 35px !important;
}
.margin-right-40 {
  margin-right: 40px !important;
}
.margin-right-45 {
  margin-right: 45px !important;
}
.margin-right-50 {
  margin-right: 50px !important;
}
.margin-right-60 {
  margin-right: 60px !important;
}
.margin-right-70 {
  margin-right: 70px !important;
}
.margin-right-80 {
  margin-right: 80px !important;
}

@media (max-width: @screen-xs-max) {
  .margin-xs-0 {
    margin: 0 !important;
  }
}

@media (min-width: @screen-sm-min) {
  .margin-sm-0 {
    margin: 0 !important;
  }
}

@media (min-width: @screen-md-min) {
  .margin-md-0 {
    margin: 0 !important;
  }
}

@media (min-width: @screen-lg-min) {
  .margin-lg-0 {
    margin: 0 !important;
  }
}
