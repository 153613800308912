
.page-header + .page-content {
  padding-top: 0;
}

.page-title {
  font-size: 26px;
  margin-top: 0;
  margin-bottom: 0;

  > .icon {
    margin-right: 0.3em;
  }
}

.page-description {
  color: @headings-small-color;
}

.page-header {
  padding: @page-header-vertical-padding @page-horizontal-padding;

  margin-top: 0;
  margin-bottom: 0;
  background: @page-header-bg;
  position: relative;
  border-bottom: none;

  &-actions {
    position: absolute;
    right: @page-horizontal-padding;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;

    .btn-icon {
      margin-left: 6px;
    }

    > * {
      margin-bottom: 0;
    }
  }

  .breadcrumb {
    padding: 0;
    margin: 0;
  }
}

.page-header-bordered {
  padding-top: @page-header-bordered-vertical-padding;
  padding-bottom: @page-header-bordered-vertical-padding;
  background-color: @inverse;
  margin-bottom: @page-vertical-padding;
  border-bottom: 1px solid @page-header-border-color;
}

.page-header-tabs {
  padding-bottom: 0;

  .nav-tabs-line {
    margin-top: 5px;
    border-bottom-color: transparent;
    > li > a {
      padding: 5px 20px;
    }
  }
}
