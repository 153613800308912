.tag{
  display: inline-block;
  margin-right: 5px;
  background: @color-blue-grey-200;
  font-size:11px;
  padding: 2px 5px;
  border-radius: 3px;

  &.category1{
    background: @color-cyan-600;
    color: #fff;
  }

  &.category2{
    background: @color-purple-600;
    color: #fff;
  }
}

.tag-agent{
  display: inline-block;
  margin-right:10px;
  font-size:12px;
}