
.site-menu {
  font-size: 14px;
  overflow-y: hidden;
  padding-bottom: 20px;

  &-item > a {
    white-space: nowrap;
    cursor: pointer;
  }

  &-icon {
    display: inline-block;

    .icon-fixed-width();
  }

  &-title {
    vertical-align: middle;

    .text-overflow();
  }

  &-arrow {
    font-family: '@{wb-font-family}';

    .transition(~"transform 0.15s");

    .make-icon();
    &:before {
      content: '@{wb-var-chevron-right-mini}';
    }
  }

  &-arrow, &-badge, &-label {
    position: absolute;
    right: @site-menu-padding-horizontal;
    display: inline-block;
    vertical-align: middle;
  }

  .badge, .label {
    padding: 2px 5px 3px;
    font-size: @font-size-small;
    font-weight: @font-weight-normal;
  }

  &-item {
    position: relative;
    white-space: nowrap;

    a {
      display: block;
      color: @site-menubar-color;
    }

    &.is-hidden {
      opacity: 0;
    }

    &.is-shown {
      opacity: 1;
    }
  }

  > .site-menu-item {
    padding: 0;

    &.open {
      background: @site-menu-top-open-bg;

      > a {
        color: @site-menu-top-open-color;
        background: @site-menu-top-open-head-bg;
      }

      &.hover {
        > a {
          background: @site-menu-top-open-head-bg;
        }
      }
    }

    &.hover, &:hover {
      // color: @site-menu-top-hover-color;

      > a {
        color: @site-menu-top-hover-color;
        background-color: @site-menu-top-hover-bg;
      }
    }

    &.active {
      background: @site-menu-top-active-bg;

      border-top: 1px solid @site-menu-top-active-border-color;
      border-bottom: 1px solid @site-menu-top-active-border-color;

      > a {
        color: @site-menu-top-active-color;
        background: @site-menu-top-active-head-bg;
      }

      &.hover {
        > a {
          background: @site-menu-top-active-head-bg;
        }
      }
    }
  }

  .site-menu-sub {
    padding: 5px 0;
    margin: 0;
    font-size: 14px;
    background: @site-menu-sub-bg;

    .site-menu-item {
      line-height: @site-menu-sub-height;
      color: @site-menu-sub-color;
      background: transparent;

      &.has-sub {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        // &.open {
        //   border-top-color: @site-menu-has-sub-border-color;
        //   border-bottom-color: @site-menu-has-sub-border-color;
        // }
      }

      > a {
        padding: 0 @site-menu-padding-horizontal;
      }

      &.open {
        background: @site-menu-sub-open-bg;

        > a {
          color: @site-menu-sub-open-color;
        }

        &.hover {
          > a {
            background-color: transparent;
          }
        }
      }

      &.hover, &:hover {
        > a {
          color: @site-menu-sub-hover-color;
          background-color: @site-menu-sub-hover-bg;
        }
      }

      &.active {
        background: @site-menu-sub-active-bg;

        > a {
          color: @site-menu-sub-active-color;
        }

        &.hover {
          > a {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.site-menubar-light {
  .site-menu {
    &-item {
      a {
        color: @site-menubar-light-color;
      }
      &.hover, &:hover {
        > a {
          background: @site-menu-light-top-open-head-bg;
        }
      }
    }
    > .site-menu-item {
      &.open {
        background: @site-menu-light-top-open-bg;
        > a {
          color: @site-menu-light-sub-open-color;
        }
      }

      &.hover, &:hover {
        background-color: @site-menu-light-top-hover-bg;
        > a {
          color: @site-menu-light-top-hover-color;
        }
      }

      &.active {
        background: @site-menu-light-top-active-bg;
        > a {
          color: @site-menu-light-top-active-color;
        }
      }
    }
    .site-menu-sub {
      .site-menu-item {
        &.open {
          background: @site-menu-light-sub-open-bg;
          > a {
            color: @site-menu-light-sub-open-color;
          }
        }

        &.hover, &:hover {
          background-color: @site-menu-light-sub-active-bg;
          > a {
            color: @site-menu-light-sub-hover-color;
          }
          &.open {
            background-color: @site-menu-light-sub-open-bg;
          }
        }

        &.active {
          background: @site-menu-light-sub-active-bg;
          > a {
            color: @site-menu-light-sub-active-color;
          }
        }
      }
    }
  }
}
