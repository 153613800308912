//@import "vars.less";


#win-loss{
  height:95px;
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  z-index: 200;
}

.app-taskboard {




  .ui-tooltip {
    display: none !important;

  }

  .ui-helper-hidden-accessible {
    display: none;
  }

  .control-content {
    .bootstrap-select {
      width: 100% !important;
    }
  }

  .page {
    height: ~'calc(100% - 44px)';
    overflow: hidden;

    &-content {
      height: ~'calc(100% - 91px)';
      width: 100%;
      overflow-x: auto;
    }
  }

  .subtasks {
    .checkbox-custom {
      input:checked + label {
        text-decoration: line-through;
      }
    }
  }

  .slidePanel {
    .overlay-panel a:not([class]) {
      text-decoration: none;
    }

    &-header {
      .btn {
        .focus, &:focus, &:hover {
          color: @gray-lighter;
        }
      }
    }

    &-actions {
      min-height: 0;

      .btn {
        line-height: 1em;
      }
    }

    &-inner {
      padding: 0 30px;
    }

    .caption {
      font-size: 22px;
      font-weight: normal;
      line-height: 1;
    }

    .priority {
      .list-inline > li {
        padding-left: 20px;
        padding-right: 0;
        label {
          font-size: 0;
          color: transparent;
        }
      }
    }

    .addMember {
      margin-top: 30px;
      margin-bottom: 20px;

      &-trigger {
        vertical-align: top;
      }

      &-items {
        display: inline;
      }

      &-item {
        margin-bottom: 10px;
      }

      &-trigger-dropdown {
        left: auto;
        top: 40px;
        z-index: 1;
        &:before {
          border-color: transparent transparent white;
          left: 4px;
          top: -20px;
        }
      }
    }

    .description {
      &-content {
        margin-bottom: 20px;
      }

      &-toggle {
        display: none;
        margin-bottom: 20px;
      }

      &.is-empty {
        .description-toggle {
          display: inline-block;
        }
      }
    }

    .radio-custom {
      &.radio-high {
        label {
          &::before {
              border-color: @color-orange-600;
          }

          &::after {
            border-color: @inverse;
          }
        }
      }

      &.radio-urgent {
        label {
          &::before {
              border-color: @color-red-600;
          }
          &::after {
            border-color: @inverse;
          }
        }
      }
    }

    .overlay-panel {
      .dropdown-menu {
        color: @gray-dark;
      }
    }

    .subtasks {
      &-add {
        display: none;
      }

      &.is-edit {
        .subtasks-add {
          display: block;
        }
      }

      &-list {
        .checkbox-custom {
          margin-top: 0;
          margin-bottom: 0;
        }

        .subtask-editor {
          display: none;
        }
      }

      .subtask.is-edit {
        .checkbox-custom {
          display: none;
        }

        .subtask-editor {
          display: block;
        }
      }
    }


    .attachments {
      .list-group-item {
        &:hover {
          .attachments-actions {
            display: inline-block;
          }
        }
        .size {
          display: inline-block;
          width: 80px;
          color: @color-blue-grey-400;
        }
      }

      &-list {
        margin-bottom: 0;
      }

      &-actions {
        display: none;
      }

      &-image {
        width: 80px;
        line-height: 70px;
        text-align: center;
        border: 1px solid @color-blue-grey-200;
        padding: 2px;
        > img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .btn {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1;
        vertical-align: baseline;
        color: @gray;

        &:hover {
          color: @gray-dark;
        }
      }

      .icon {
        margin-right: 0;
      }
    }

    .slidePanel-inner-section {
      &:last-child {
        border-bottom: none;
      }
    }

    .task-section {
      display: none;

      &.is-show {
        display: block;
      }
    }

    .task-main {
      &.is-edit {
        .task-main-surface {
          display: none;
        }

        .task-main-editor {
          display: block;
        }
      }

      &-editor {
        display: none;
      }
    }
  }

  #fileupload {
    display: none;
  }
}

.taskboard {
  &-stages {
    height: 100%;
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    white-space: nowrap;
  }

  &-stage {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 260px;
    height: 100%;
    margin-right: 5px;
    background-color: @inverse;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    border-width: 0;
    margin-top: -1px;

    &.ui-sortable-helper {
      max-height: none;
    }

    &-rename-wrap {
      display: none;
      padding: 6px 10px !important;
    }

    &-header {
      padding: 16px 20px;
   //   border-top-left-radius: 4px;
     // border-top-right-radius: 4px;
      border-bottom: 1px solid @border-color-base;
      //background-color: @color-blue-grey-200;

    .stage-amount{
      color: #fff;
      font-weight: bold;
      font-size: 12px;
    }

      > h1, h2, h3, h4, h5, h6 {
        margin: 0;
        color: #fff;
      }

      .dropdown-menu {
        left: -10px;

        > li {
          width: 200px;

          > a {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &.is-edit {
          [role="presentation"] {
            display: none;
          }

          .taskboard-stage-rename-wrap {
            display: block;
          }
        }
      }
    }

    &-actions {
      .dropdown-toggle {
        color: @gray-dark;

        &:hover, .open& {
          color: @gray;
        }
      }
    }

    &-content {
      height: ~'calc(100% - 49px)';
      overflow: auto;

      .action-wrap {
        padding: 20px;

        &.action-open {
          .add-item-toggle {
            display: none;
          }

          .add-item-wrap {
            display: block;
          }
        }
      }

      .add-item-toggle {
        display: block;
        color: @gray-darkest;

        &:focus, &:hover {
          color: @gray-dark;
          text-decoration: none;
        }

        > .icon {
          margin-right: 6px;
          vertical-align: text-top;
        }
      }

      .add-item-wrap {
        display: none;
      }

      .add-item-priority {
        display: block;
      }
    }
  }

  &-list {
    min-height: 30px;
    margin-bottom: 0;
    margin-top: -1px;

    .list-group-item {
      padding: 12px 20px;
      border-top: 1px solid @border-color-base;
      border-bottom: 1px solid @border-color-base;
      border-left: 3px solid transparent;
      cursor: pointer;
      .clearfix();

      .task-badge {
        float: left;
        margin-top: 10px;
        color: @gray;
        line-height: 30px;
        &.icon:before {
          margin-right: 6px;
        }
      }

      .time{
        display:block;
        font-size: 12px;
      }

      .task-action {
        float: right;
        text-align: right;
        white-space: normal;
        padding: 0;
        margin-top: 15px;
        .btn{
          box-shadow: none;
          width: 25px;
          height: 25px;

          i{
            margin:0 auto;
            font-size: 13px;
          }
        }
      }

      &.priority-high {
        border-left-color: @color-orange-600;
      }

      &.priority-urgent {
        border-left-color: @color-red-600;
      }

      &:first-child, &:last-child {
        border-radius: 0;
      }

      &.ui-sortable-helper {
        border-color: @border-color-base;
      }

      &.ui-sortable-placeholder {
        background-color: @gray-lighter;
        visibility: visible !important;
      }

      > .checkbox-custom {
        margin: 0;

        input:checked + label {
            text-decoration: line-through;
        }
      }

      > .list-group-item-title {
          margin-left: 30px;
          white-space: normal;
      }
    }
  }
}
