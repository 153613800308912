// Name:            Dotnav
//
// Component:       `dotnav`
//
// Modifiers:       'dotnav-scaleup'
//                  'dotnav-stroke'
//                  'dotnav-fillin'
//                  'dotnav-dotstroke'
//                  'dotnav-fall'
//
// ========================================================================

// Variables
// ========================================================================

@dotnav-list-size:                       16px;
@dotnav-list-margin:                     0 16px;
@dotnav-list-bgColor:                    rgba(255, 255, 255, 0.3);
@dotnav-list-active-bgColor:             @inverse;

@dotnav-stroke-list-active-border-color: @inverse;

// Component: Dotnav
// ========================================================================

.dotnav {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: none;

  > li {
    position: relative;
    display: inline-block;
    width: @dotnav-list-size;
    height: @dotnav-list-size;
    margin: @dotnav-list-margin;
    vertical-align: top;
    list-style: none;

    > a {
      display: inline-block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;
      cursor: pointer;
      background-color: @dotnav-list-bgColor;
      border-radius: 50%;
      &:focus {
        outline: none;
      }
    }
    &.active,
    &:hover,
    &:focus {
      > a {
        background-color: @dotnav-list-active-bgColor;
      }
    }
  }
}

// Modifier: `dotnav-scaleup`
// ========================================================================

.dotnav-scaleup {
  > li {
    > a {
      .transition(~'transform 0.3s ease 0s, background-color 0.3s ease 0s');
    }

    &.active {
      > a {
        .scale(1.5);
      }
    }
  }
}

// Modifier: `dotnav-stroke`
// ========================================================================

.dotnav-stroke {
  > li {
    > a {
      border: 2px solid transparent;
      .transition(~'border 0.3s ease 0s, background-color 0.3s ease 0s');
    }
    &.active {
      > a {
        background-color: transparent;
        border-color: @dotnav-stroke-list-active-border-color;
        .scale(1.3);
      }
    }
  }
}

// Modifier: `dotnav-fillin`
// ========================================================================

.dotnav-fillin {
  > li {
    > a {
      background-color: transparent;
      box-shadow: 0 0 0 2px #fff inset;
      .transition(~'box-shadow 0.3s ease 0s');
    }
    &:hover,
    &:focus {
      > a {
        background-color: transparent;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, .6) inset;
      }
    }
    &.active {
      > a {
        box-shadow: 0 0 0 8px #fff inset;
      }
    }
  }
}

// Modifier: `dotnav-dotstroke`
// ========================================================================

.dotnav-dotstroke {
  > li {
    > a {
      box-shadow: 0 0 0 8px rgba(255, 255, 255, .5) inset;
      .transition(~'box-shadow 0.3s ease 0s');
    }
    &.active {
      > a {
        background-color: rgba(255, 255, 255, .3);
        box-shadow: 0 0 0 2px #fff inset;
      }
    }
  }
}

// Modifier: `dotnav-fall`
// ========================================================================

.dotnav-fall {
  > li {
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      visibility: hidden;
      content: '';
      background-color: rgba(0, 0, 0, .3);
      border-radius: 50%;
      opacity: 0;
      position: absolute;
      .translate(0%, -200%);
      .transition(~'transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s');
    }

    > a {
      .transition(~'transform 0.3s ease 0s, opacity 0.3s ease 0s, background-color 0.3s ease 0s');
    }

    &.active {
      &:after {
        visibility: visible;
        opacity: 1;
        .translate(0%, 0%);
        .transition(~'transform 0.3s ease 0s, opacity 0.3s ease 0s');
      }
      > a {
        opacity: 0;
        .translate(0, 200%);
      }
    }
  }
}