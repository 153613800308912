

.site-sidebar-nav.nav-tabs > li > a{
  line-height: 45px;
}

.site-sidebar-tab-content {
//  strong{
//    color: #37474f;
//  }
  h4 {
   // color: @color-blue-700;
    margin-top: 20px;
  }
}

.slidePanel-inner-section{
  &.with-tabs{
    padding-top:15px;
  }
  padding-bottom: 100px;
}



.slidePanel .bootstrap-select .btn{
  background: none;
  border:none;
  padding:5px;


  .caret{
    display:none;
  }

}

.slidePanel .bootstrap-select {
  width:auto !important;

  .dropdown-menu>li>a {
    padding: 3px 8px;
    text-decoration: none;
  }

}

.contact-slidepanel{
  h3{
    margin-top:0;
  }
  .avatar{
    width: 90px;
    height: 90px;
    margin-right: 20px;

    .avatar-initials{
      height: 90px;
      font-size: 22px;
      padding-top: 25px;
    }
  }
}

