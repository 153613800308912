#win-loss{
  top:-100px;

  //-webkit-transition: all .2s; /* Safari */
  //transition: all .2s;

  &.inplace{
    top:0;
  }

  .list-group-item{
    display:none;


    &.ui-sortable-placeholder{
      display:block;
    }
  }
}

.taskboard-stage-header.label-default{
  h5{
    color: #666;
  }
}

//passed
.activity-statuskey-3{
    color: @color-red-700 !important;
}