// Name:            Avatar
//
// Component:       `avatar`
//
// Modifiers:       `avatar-online`
//                  `avatar-off`
//                  `avatar-busy`
//                  `avatar-away`
//                  `avatar-lg`
//                  `avatar-sm`
//                  `avatar-xs`
//
// Markup:
//
// <!-- avatar -->
// <span class="avatar">
//   <img src="" />
//   <i></i>
// </span>
//
// ========================================================================

// Variables
// ========================================================================

@avatar-size-100:        100px;
@avatar-status-size-100: 20px;

@avatar-online-color:    @brand-success;
@avatar-off-color:       @brand-dark;
@avatar-busy-color:      @brand-warning;
@avatar-away-color:      @brand-danger;

@avatar-status-border:   @inverse;

// Component: Avatar
// ========================================================================

.avatar {
  position: relative;
  display: inline-block;
  width: @avatar-size;
  white-space: nowrap;
  border-radius: 1000px;
  vertical-align: bottom;

  i {
    position: absolute;
    right: 0;
    // top: 0;
    bottom: 0;
    width: @avatar-status-size;
    height: @avatar-status-size;
    border: 2px solid @avatar-status-border;
    border-radius: 100%;
  }

  img {
    width: 100%;
    max-width: 100%;
    height: auto;

    border: 0 none;
    border-radius: 1000px;
  }
}


// Status Modifier
// ========================================================================

.avatar-online {
  i {
    background-color: @avatar-online-color;
  }
}

.avatar-off {
  i {
    background-color: @avatar-off-color;
  }
}

.avatar-busy {
  i {
    background-color: @avatar-busy-color;
  }
}

.avatar-away {
  i {
    background-color: @avatar-away-color;
  }
}

// Size Modifier
// ========================================================================

.avatar-100 {
  width: @avatar-size-100;

  i {
    height: @avatar-status-size-100;
    width: @avatar-status-size-100;
  }
}

.avatar-lg {
  width: @avatar-size-lg;

  i {
    height: @avatar-status-size-lg;
    width: @avatar-status-size-lg;
  }
}

.avatar-sm {
  width: @avatar-size-sm;

  i {
    height: @avatar-status-size-sm;
    width: @avatar-status-size-sm;
  }
}

.avatar-xs {
  width: @avatar-size-xs;

  i {
    height: @avatar-status-size-xs;
    width: @avatar-status-size-xs;
  }
}
