@import "custom/image-editor.less";
@import "custom/panel-portlets.less";
@import "custom/widgets.less";
@import "custom/templates.less";
@import "custom/normalize.less";
@import "custom/aside.less";
//@import "custom/tinymce.less";
@import "custom/message.less";
@import "custom/languageswitch.less";
@import "custom/tags.less";
@import "custom/selectize.less";
@import "custom/tablerender.less";
@import "custom/documents.less";
@import "custom/buttons.less";
@import "custom/radio.less";
@import "custom/labels.less";
@import "custom/pipeline.less";
@import "custom/sidebar.less";
@import "custom/search.less";
@import "custom/map.less";
@import "custom/dashboard.less";
@import "custom/selectpicker.less";
@import "custom/properties.less";
@import "custom/proposed.less";
@import "custom/framewarp.less";
@import "custom/mappings.less";
@import "custom/builder.less";

body{
  font-size: 13px;
}

.cal-action-done{
  //opacity: .1;
}

.site-action-left{
  left: 32px;
  right: auto;
}

a.avatar{
  text-decoration: none;
}
.avatar-initials{
    display:block;
    height: 40px;
    background: @color-blue-grey-300;
    font-size:14px;
    //font-weight: bold;
    border-radius: 50%;
    text-align:center;
    padding-top: 10px;
  text-decoration: none;
  //color: @color-blue-grey-400;
  color: #fff;


}

.inner-actions{
  position: absolute;
  top:15px;
  right:15px;
}

.no-background{
  background: none !important;
}

.panel.clean{
  background:none;
  border:none;
  box-shadow: none;
}
.site-menubar{
  //background:#241740;
  background: #222733;
  padding-top:15px;
  width: 260px;
}

.site-menubar-unfold .site-menu>.site-menu-item>a .site-menu-title {
  font-size: 15px;
}

.site-menubar-unfold .site-menu > .site-menu-item > a {
  line-height: 35px;
}

.table .bootstrap-select .btn{
  background: none;
  border:none;
  padding:5px;


  .caret{
    display:none;
  }

}

.table .bootstrap-select {
  width:auto !important;

  .dropdown-menu>li>a {
    padding: 3px 8px;
    text-decoration: none;
  }

}

.page-dark{
  &.layout-full {
    &:after {
      background-color: fade(@gray-base, 80%);
    }
  }
}

.pearls{
  .badge{
    position:absolute;
    top:-10px;
    right: -10px;
  }
}
.pearls.row{
  margin:0 0 15px 0;
}

.pearl-title{
  font-size: 14px;
}

.sortablefield {
  cursor: pointer;

  &.active{
    font-weight: bold;
  }
}

/*
Similar to no-borders option
but because of Laravel pagination
we hack'it directly from here
*/
.pagination{
  li a, li span {
    border: none;
  }
}

.page-header{
 // padding:30px 30px 0px;
}

.panel.is-fullscreen{
  overflow: auto;
}

//toaster remove shadow
#toast-container>div{
  -moz-box-shadow:none;
 -webkit-box-shadow:none;
  box-shadow: none;
}



.panel .panel-heading{
  min-height: 50px;
}
//filter-result paging
.paging {
  min-width: 50px;

  li a{
    text-align: right;
    &.active {
      color: @brand-info;

    }
  }
}

.panel.is-fullscreen{
  .panel-heading{
    position:fixed;
    width: 100%;
    height: 50px;
    opacity: .7;
    background: #fff;
    z-index: 999;
    &:hover{
      opacity: 1;
    }
  }

  .panel-body{
   padding-top: 60px;
  }

  ul.pagination{
    position:fixed;
    bottom:10px;
    left:100px;
  }
}

//BOOTBOX
.modal-backdrop.in{
  opacity:.3;
}

.bootbox.modal{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  height: 100%;
  margin: 0 auto;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: center;
  align-content: center;
}


@pearl-done-color:            @inverse;
@pearl-done-bg:               @brand-primary;
@pearl-done-line-bg:          @brand-primary;
@pearl-done-border-color:     @pearl-done-bg;

//Pearls
.pearls-done {
  .pearl {
//    .pearl-number, .pearl-icon {
//      color: @pearl-done-color;
//      background-color: @pearl-done-bg;
//      border-color: @pearl-done-border-color;
//    }

    &:before, &:after {
      background: none;
    }

    &:hover{
      text-decoration: none;

      .pearl-icon{
        //background-color: darken(@pearl-done-bg, 10%);
      }

      .pearl-title {
        color: @pearl-done-bg;
      }
    }

  }
}



.help-block{
  display: block;
  float:left;
  height: 0;
  font-size: 85%;
}


//Dropzone
.dropzone{
  border: 2px dotted rgba(0, 0, 0, .1);
  margin-top: 15px;
  text-align: center;
  
  .dz-message{
    font-size: 16px;
    opacity: 0.3;
    text-transform: uppercase;
    margin-top: 40px;
    display: block;
   text-align: center;
    //font-weight: 400;

  }

}


.site-menu > .site-menu-item.active {

  background: fade(#62a8ea,20%);
}

.table-header-pagination{
  margin-top:-60px;
}


.timeline-normal {
  .timeline-content{
    text-align: right;
  }
}

//ROTATES
.rotate90 {
  -ms-transform: rotate(90deg) !important; /* IE 9 */
  -webkit-transform: rotate(90deg) !important; /* Safari */
  transform: rotate(90deg) !important;
}

.h4-title{
  text-transform: uppercase;
  font-size:14px;
}

h4{
  font-size:14px;
  text-transform: uppercase;

}

/*SLIDE PANEL */
.slidePanel-header{
  padding: 30px 30px;

}

/*CALENDAR */
.fc-event{
  border:none !important;
}

/*SELECT 2 */
.select2-container{
  margin-bottom: 20px;
}

/*INPUTS */
.input-min
{
  &:after{
    content: '-';
    display: block;
    top:4px;
    position: absolute;
    right: -3px;
  }
}

/*TABLE*/
.table{
  font-size: 13px;
}

/* CLOCKPICKER */
.clockpicker-popover{
  z-index: 1500;
}

/* POPOVERS */
.popover{
  max-width: none;

  h4{
    margin-top:0;
  }
}
.popover-wrapper{
  width: 300px;
}

/*SMALL BUTTONS WITH ICONS*/
.btn-icon.btn-sm{
  padding:5px;
}

.no-overflow{
  overflow: hidden;
}

/* model select */
.modelselect{
  max-width: 200px;
  display: inline-block;
  color: #57c7d4;
  border-color: #57c7d4;
  margin-right: 5px;
  height: 31px;
  border-radius: 2px;

}