@site-page-unfold: {
  .page, .site-footer {
    margin-left: @site-menubar-unfold-width;
  }
};

@site-navbar-unfold: {
  .site-navbar {
    .navbar-header {
      @media (min-width: @grid-float-breakpoint) {
        width: @site-menubar-unfold-width;
      }
    }

    .navbar-container {
      @media (min-width: @grid-float-breakpoint) {
        margin-left: @site-menubar-unfold-width;
      }
    }
  }
};

@site-menu-unfold: {
  .site-menu {
    &-category {
      .transition(~"all .25s, font .1s .15s, color .1s .15s");

      font-size: 15px;
      padding: 0 24px;
      color: @site-menu-category-color;
      line-height: @site-menu-top-height;
      text-transform: uppercase;
      margin-top: 20px;
    }

    > .site-menu-item {
      > a {
        .site-menu-title {
          font-size: @site-menu-top-font-size;
        }
      }
    }

    &-icon {
      margin-right: @site-menu-top-font-size;
    }

    &-title {
      display: inline-block;
      max-width: 160px;

      &:first-child {
        margin-left: (2 * @site-menu-top-font-size);
      }
    }

    .site-menu-sub {
      display: none;
    }

    &-item {
      .transition(~"transform .1s, all .25s, border 0");
      line-height: @site-menu-sub-height;

      &.open {
        > a {
          .site-menu-arrow {
            .transform(rotate(90deg));
          }
        }

        > .site-menu-sub {
          display: block;
        }
      }

      &:not(.open) {
        > a {
          .site-menu-arrow {
            .transform(none);
          }
        }

        > .site-menu-sub {
          display: none;
        }
      }
    }

    > .site-menu-item {
      > a {
        padding: 0 30px;
        line-height: @site-menu-top-height;
      }
    }
  }
};

@site-menubar-unfold: {
  &.site-menubar-native {
    .site-menubar-body {
      overflow-y: scroll;
    }
  }

  .site-menubar {
    width: @site-menubar-unfold-width;
  }
};

@site-gridmenu-unfold: {
  .site-gridmenu {
    width: @site-menubar-unfold-width;
  }
};

@site-menubar-footer-unfold: {
  .site-menubar-footer {
    width: @site-menubar-unfold-width;
  }
};

@site-menubar-toggle-unfold: {
  [data-toggle="menubar"] {
    .hamburger-arrow-left {
      .hamburger-arrow-left();
    }
  }
};

@media screen and (max-width: @grid-float-breakpoint-max),
screen and (min-width: @screen-lg-min) {
  .css-menubar {
    // @site-page-unfold();
    @site-navbar-unfold();
    @site-menu-unfold();
    @site-menubar-unfold();
    @site-gridmenu-unfold();
    @site-menubar-footer-unfold();
    @site-menubar-toggle-unfold();

    .site-menu-item {
      &:hover {
        > .site-menu-sub {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: @screen-lg-min) {
  .css-menubar {
    @site-page-unfold();
  }
}

//.js-menubar {
  .site-menubar-unfold {
    @site-page-unfold();

    @site-navbar-unfold();
    @site-menu-unfold();
    @site-menubar-unfold();
    @site-gridmenu-unfold();
    @site-menubar-footer-unfold();
    @site-menubar-toggle-unfold();
  }
//}

@media (max-width: @grid-float-breakpoint-max) {
  //.js-menubar {
    .site-menubar-unfold {
      .page, .site-footer {
        margin-left: auto;
      }
    }

    .site-menubar-open {
      .page, .site-footer {
        -ms-transform: translate(@site-menubar-unfold-width, 0);
        .transform(translate3d(@site-menubar-unfold-width, 0, 0));
      }
    }

    .site-menubar-changing {
      overflow: hidden;

      .page, .site-footer {
        .transition(~"transform .25s");
      }
    }

    .site-gridmenu {
      width: 100% !important;
      background: fade(@site-gridmenu-bg, 90%);
    }
  //}

  .site-menubar {
    .site-menubar-hide &,
    .site-menubar-open & {
      .transition(~"transform .25s, top .35s, height .35s");
    }

    .site-menubar-open & {
      -ms-transform: translate(0, 0);
      .transform(translate3d(0, 0, 0));
    }
  }
}
