// Name:            Example
//
// Component:       `example`
//
//
// ========================================================================

// Variables
// ========================================================================

@example-title-text-shadow:     @color-white;
@example-title-span-color:      #ED5565;

@example-typography-note-color: #AAB2BD;
@example-well-bg:               #f3f7f9;

// Component: Example
// ========================================================================
.example-wrap {
  margin-bottom: 80px;

  .example-wrap {
    margin-bottom: 0;
  }
}

.example {
  margin-top: 20px;
  margin-bottom: 20px;

  .clearfix();
}

// Modifier: `example-title`
// ========================================================================

.example-title {
  &, h4& {
    font-size: 14px;
  }

  text-transform: uppercase;
}

// Panel
// ========================================================================

.panel-body {
  > .example-wrap:last-child {
    margin-bottom: 0;
  }
  > .row:last-child {
    > [class*="col-"]:last-child {
      .example-wrap:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Modifier: `example-well`
// ========================================================================

.example-well {
  position: relative;
  margin-bottom: 30px;
  background-color: @example-well-bg;

  .center {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    .transform(translate(-50%, -50%));
  }
}

// Modifier: `example-dropdown`
// ========================================================================

.example-dropdown {
  .dropdown,
  .dropup {
    .clearfix();

    > .dropdown-toggle {
      float: left;
    }

    > .dropdown-menu {
      position: static;
      display: block;
      clear: left;
    }

    > .dropdown-menu-right {
      float: right;
      clear: right;
    }
  }
}

// Modifier: `example-tooltip`
// ========================================================================

.example-tooltip {
  position: relative;
  display: inline-block;
  z-index: 1;

  .tooltip {
    position: relative;
    margin-right: 25px;
    opacity: 1;
  }
}

// Modifier: `example-grid`
// ========================================================================

.example-grid .example-col, .example-blocks .example-col {
  min-height: 0;
  padding: 10px 15px 12px;
  background-color: @example-well-bg;
  border-radius: 0;
}

.example-grid .example-col {
  margin-bottom: 20px;
}

.example-grid .example-col .example-col {
  margin-top: 20px;
  margin-bottom: 0;
  background-color: darken(@example-well-bg, 5%);
}


// Modifier: `example-popover`
// ========================================================================

.example-popover {
  position: relative;
  display: inline-block;
  z-index: 1;

  .popover {
    position: relative;
    display: block;
    margin-right: 25px;
  }
}


// Modifier: `example-buttons`
// ========================================================================

.example-buttons {
  .btn,
  .btn-group,
  .btn-group-vertical {
    margin-right: 15px;
    margin-bottom: 20px;
  }

  .btn-group-vertical .btn,
  .btn-group .btn {
    margin-right: 0;
    margin-bottom: 0;
  }
}

// Modifier: `example-box`
// ========================================================================

.example-box {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  padding: 45px 15px 15px;
  border: 1px solid @border-color-base;

  &:after {
    position: absolute;
    top: 15px;
    left: 15px;
    content: "Example";
    color: #959595;
    font-size: @font-size-small;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

}
// Modifier: `example-avatars`
// ========================================================================

.example-avatars {
  .avatar {
    margin-right: 20px;
    margin-bottom: 20px;

    &:last-child {
      margin-right: 20px;
    }
  }
}

// Modifier: `example-typography`
// ========================================================================

.example-typography {
  position: relative;
  padding-left: 25%;

  .heading-note,
  .text-note {
    position: absolute;
    bottom: 2px;
    left: 0;
    display: block;
    width: 260px;
    font-size: 13px;
    font-weight: @font-weight-normal;
    line-height: 13px;
    color: @example-typography-note-color;
  }

  .text-note {
    top: 10px;
    bottom: auto;
  }
}


// Modifier: `example-typography`
// ========================================================================
.example-responsive {
  overflow-x: auto;
  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

  @media screen and (max-width: @screen-xs-max) {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
