.ppt-image-box{
  padding:10px;
  margin:10px;
  position: relative;

  .checkbox-custom{
    position:absolute;
    top:10px;
    left:15px;
  }
}